<template>
  <div class="card card-body filterItem small bgbox flightpagelist" :class="[isShowHotIcon?'bgbox-discount':type==='check-booking'? '':'bgbox-default', selectIdx === idx ? 'selected' : '']" >
    <!-- <img :src="`${speedSizeDomain}/assets/img/hot-offer.png`" v-if="flight.originalPrice" class="hot-offer-icon"/> -->
    <div class="row" @click="selectFlight">
      <p class="ticketLeft mb-1" v-if="Math.min(flight.avl1, flight.avl2) > 0">
        {{ Math.min(flight.avl1, flight.avl2) > 8 ? '' : `${$t("search-result.number-seats-left")}: ${Math.min(flight.avl1,flight.avl2)}`}}
      </p>
      <div :class="(type === 'product' || type === 'check-booking') ? 'col-12 col-md-12' : 'col-12 col-md-10'">
        <div class="row">
          <div class="col-5 col-md-5 border-md-right">
            <div class="ticket-topPart">
              <div class="flight-logo">
                <img v-if="!flight.FlightDetail[0].airlineNotConfirmed" :src="(this.flight.FlightDetail[0].airlineInfo && this.flight.FlightDetail[0].airlineInfo.desc2) || `${speedSizeDomain}/assets/img/flying.png`" alt="flight" class="img-fluid" width="110" height="90"/>
              </div>
              <div>
                <span class="flightName d-block">{{ convertDateFormat(flight.FlightDetail[0].FL_Date) }}</span>
                <span class="flightName d-block">{{ flight.FlightDetail[0].airlineInfo.desc1 || '' }}</span>
                <span class="flightName d-block">{{
                  (flight.FlightDetail[0].airlineNotConfirmed && flight.FlightDetail[0].flightTimeNotConfirmed ? '' :`${this.flight.FlightDetail[0].airlineInfo.code || this.flight.FlightDetail[0].FL_AIRLINE} - ${flight.FlightDetail[0].FL_Flt_Number}`) }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-7 col-md-7">
            <div class="ticket-topPart-big">
              <div class="col-md-12 d-flex justify-content-between">
                <div class="box">
                  <span class="d-block">{{ (flight.translations && flight.translations.flightDestinationName[flight.FlightDetail[0].FL_From_Route] && flight.translations.flightDestinationName[flight.FlightDetail[0].FL_From_Route][lang]) || flight.FlightDetail[0].FL_From_Route }}</span>
                  <span class="time">{{ flight.FlightDetail[0].FL_Dep_Hour }}</span>
                  <span class="d-block">{{ getWeek(flight.FlightDetail[0].FL_Date) }}</span>
                </div>
                <div class="box-middle">
                  <img class="img-fluid" :src="`${speedSizeDomain}/assets/img/plane-from.png`" alt="plan-from" width="97" height="27"/>

                  <div class="d-flex justify-content-between mt-3 w-100">
                    <i class="customIcon icon-bag">
                      <span class="count">{{ maxBagOutward }}</span>
                    </i>
                    <i class="customIcon icon-luggage">
                      <span class="count">{{ maxLuggageOutward }}</span>
                    </i>
                  </div>
                </div>
                <div class="box">
                  <span class="d-block">{{ (flight.translations && flight.translations.flightDestinationName[flight.FlightDetail[0].FL_To_Route] && flight.translations.flightDestinationName[flight.FlightDetail[0].FL_To_Route][lang]) || flight.FlightDetail[0].FL_To_Route }}</span>
                  <span class="time">{{ flight.FlightDetail[0].FL_Arrv_Hour }}</span>
                  <span class="d-block">{{ getWeek(flight.FlightDetail[0].landingDate) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="remark" >
          <p>{{ translatedMessage }}</p>
        </div>
        <b-form-radio v-if="idx!==-1" v-model="selectIdx" :value="idx" class="flight-select" size="lg"></b-form-radio>
        <div class="row">
          <div class="col-5 col-md-5 border-md-right">
            <div class="ticket-bottomPart">
              <div class="flight-logo">
                <img v-if="!flight.FlightDetail[1].airlineNotConfirmed" :src="(flight.FlightDetail[1].airlineInfo && flight.FlightDetail[1].airlineInfo.desc2) || `${speedSizeDomain}/assets/img/flying.png`" alt="flight" class="img-fluid" width="110" height="90"/>
              </div>
              <div>
                <span class="flightName d-block">{{ convertDateFormat(flight.FlightDetail[1].FL_Date) }}</span>
                <span class="flightName d-block">{{ flight.FlightDetail[1].airlineInfo.desc1 }}</span>
                <span class="flightName d-block">{{
                  (flight.FlightDetail[1].airlineNotConfirmed && flight.FlightDetail[1].flightTimeNotConfirmed ? '' :`${this.flight.FlightDetail[1].airlineInfo.code || this.flight.FlightDetail[1].FL_AIRLINE} - ${flight.FlightDetail[1].FL_Flt_Number}`) }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-7 col-md-7">
            <div class="ticket-bottomPart-big">
              <div class="col-md-12 d-flex  justify-content-between">
                <div class="box">
                  <span class="d-block">{{ (flight.translations && flight.translations.flightDestinationName[flight.FlightDetail[1].FL_To_Route] && flight.translations.flightDestinationName[flight.FlightDetail[1].FL_To_Route][lang]) || flight.FlightDetail[1].FL_To_Route }}</span>
                  <span class="time">{{ flight.FlightDetail[1].FL_Arrv_Hour }}</span>
                  <span class="d-block">{{ getWeek(flight.FlightDetail[1].landingDate) }}</span>
                </div>
                <div class="box-middle">
                  <img class="img-fluid" :src="`${speedSizeDomain}/assets/img/plane-to.png`" alt="plan-to" width="97" height="27"/>

                  <div class="d-flex justify-content-between mt-3 w-100">
                    <i class="customIcon icon-bag">
                      <span class="count">{{ maxBagInward }}</span>
                    </i>
                    <i class="customIcon icon-luggage">
                      <span class="count">{{ maxLuggageInward }}</span>
                    </i>
                  </div>
                </div>
                <div class="box">
                  <span class="d-block">{{ (flight.translations && flight.translations.flightDestinationName[flight.FlightDetail[1].FL_From_Route] && flight.translations.flightDestinationName[flight.FlightDetail[1].FL_From_Route][lang]) || flight.FlightDetail[1].FL_From_Route }}</span>
                  <span class="time">{{ flight.FlightDetail[1].FL_Dep_Hour }}</span>
                  <span class="d-block">{{ getWeek(flight.FlightDetail[1].FL_Date) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="remark" >
          <p>{{ translatedReturnMessage }}</p>
        </div>
      </div>

      <div v-if="!type" class="col-12 col-md-2 border-md-left d-flex justify-content-md-center align-items-center">
        <div class="pricing d-flex text-center">
          <div class="pricing_left w-100">

            <span v-if="isOdysseySite && flight.cheapestForAgents" class="mb-2" style="line-height:1.1rem;">
              <div class="price odyssey-prices"><b>{{ `${$t('search-result.online-price')}-${getPriceWithSymbol(flight.cc, flight.minPrice)}` }}</b></div>
              <div  class="price odyssey-prices" v-if="flight.cheapestForAgents.price"><b>{{`${$t('search-result.full-price')}-${flight.cheapestForAgents.price}`}}</b></div>
              <div  class="price odyssey-prices" v-if="flight.cheapestForAgents.priceBClient"><b>{{`${$t('search-result.bclient-price')}-${flight.cheapestForAgents.priceBClient}`}}</b></div>
            </span>
            <span v-else>
              <span class="d-block title" :style="(lang==='he'?'direction:ltr':'')">: {{ $t("search-result.price") }}</span>
              <div class="price mb-1"><b>{{ getPriceWithSymbol(flight.cc, flight.minPrice ) }}</b></div>
            </span>

            <span class="mb-2">
              <i class="fas fa-info-circle instruc" tabindex="0" data-toggle="popover" data-trigger="focus" title="Instruction"
                data-content="And here's some content about instruction"
                data-placement="left"></i>
              {{ $t("search-result.total") }} : {{ getPriceWithSymbol(flight.cc, flight.totalPrice ) }}
            </span>
            <div class="mt-2">
              <button class="btn btn-continue m-auto w-100" @click="bookingFlightOnly">
                {{ $t("search-result.continued") }}
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { BFormRadio } from 'bootstrap-vue';
import gMixin from '@/utils/mixins';
import imageUrlMixin from '@/utils/imageUrlMixin';
import { translateText } from '@/utils/translate';

export default {
  mixins: [gMixin, imageUrlMixin],
  props: {
    flight: {
      type: Object,
      default: null,
    },
    categoryName: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    idx: {
      type: Number,
      default: -1,
    },
    selectIdx: {
      type: Number,
      default: 0,
    },
  },
  components: {
    BFormRadio,
  },
  data() {
    return {
      maxLuggageOutward: 0,
      maxBagOutward: 0,
      maxLuggageInward: 0,
      maxBagInward: 0,
      translatedMessage: '',
      translatedReturnMessage: '',
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      odyAgentCode: 'GET_SELECTED_ODYSSEY_AGENT_CODE',
    }),
    isShowHotIcon() {
      const isProductPage = this.$route.fullPath.indexOf('product-page');
      return !isProductPage && (this.flight.matchingDiscountRuleFO?.roundtripFlightsCode !== null || this.flight.matchingDiscountRuleFO?.ruleType === 2);
      // this.flight.matchingDiscountRuleFO?.priority === 3;
    },
    marketerId() {
      const { marketerId } = this.$route.query;
      return marketerId;
    },
  },
  watch: {
    async lang() {
      this.translatedMessage = await translateText(this.flight.FlightDetail[0].Authorization_msg, this.lang);
      this.translatedReturnMessage = await translateText(this.flight.FlightDetail[1].Authorization_msg, this.lang);
    },
  },
  async created() {
    this.maxBagOutward = this.flight.FlightDetail[0]?.FL_Max_Weight_Hand || 5;
    this.maxLuggageOutward = this.flight.FlightDetail[0]?.FL_Max_Weight || this.flight.FlightDetail[0]?.maxWeight;

    this.maxBagInward = this.flight.FlightDetail[1]?.FL_Max_Weight_Hand || 5;
    this.maxLuggageInward = this.flight.FlightDetail[1]?.FL_Max_Weight || this.flight.FlightDetail[1]?.maxWeight;
    this.translatedMessage = await translateText(this.flight.FlightDetail[0].Authorization_msg, this.lang);
    this.translatedReturnMessage = await translateText(this.flight.FlightDetail[1].Authorization_msg, this.lang);
  },
  methods: {
    async bookingFlightOnly() {
      const body = {
        packId: this.flight.PCK_ID,
        dateFrom: this.$route.query.fromDate,
        flights: `${this.flight?.fl_id1}${this.flight?.fl_id2}`,
        categoryId: this.$route.query.categoryId,
        adult: this.$route.query.adult,
        child: this.$route.query.child,
        infant: this.$route.query.infant,
      };
      if (this.marketerId) body.marketerId = this.marketerId;
      this.$router.push({ path: '/flight-only', query: body });
    },
    getWeek(str) {
      const week = [
        this.$t('weekName.sun'),
        this.$t('weekName.mon'),
        this.$t('weekName.tue'),
        this.$t('weekName.wed'),
        this.$t('weekName.thu'),
        this.$t('weekName.fri'),
        this.$t('weekName.sat'),
      ];
      return week[dayjs(str).day()];
    },
    convertDateFormat(pDate) {
      const format = 'DD-MM-YYYY';
      return dayjs(pDate).format(format);
    },
    selectFlight() {
      this.$emit('change', this.idx);
    },
  },
};
</script>
<style scoped>
.flashing .filterItem, #filter-content > .filterItem.selected {
  opacity: 1;
  animation: flashing 1s linear 1;
}
@keyframes flashing {
  0% { opacity: 1;}
  25% { opacity: 0.5;}
  50% { opacity: 0.1;}
  75% { opacity: 0.5;}
  100% { opacity: 1;}
}
.hot-offer-icon {
  width: 100px;
  height: 100px;
  top: 22px;
  left: 30px;
  position: absolute;
}

.dVacation-pack .ticketLeft {
  width: 100%;
  position: absolute;
  top: 10px;
}

.flight-img{
  padding-right: 80px;
}
.dVacation-pack{
  padding-bottom: 0px;
}
.ticket-topPart-big, .ticket-bottomPart-big{
  padding-left: 50px;
  padding-right: 50px;
}
.remark{
  margin-right: 45px !important;
}

.box span.d-block{
  min-width: 50px;
}

.price b{
  font-size: 25px;
}
.price.odyssey-prices b{
  font-size: 15px;
}

.dVacation-pack .pricing .price {
  font-weight: normal;
  font-size: 18px;
}

.flight-select {
  position: absolute;
  top: 40%;
  right: -3%;
  padding: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
}

.flight-logo .img-fluid {
  max-height: 90px;
}

@media (min-width: 479px) {
  .bgbox {
    padding: 4% 7.2%;
  }

  .hotelplusflight #filter-content > .filterItem.selected, .flashing > .filterItem {
    background-image: url("https://cdn.speedsize.com/05b4c650-ae31-47ac-b679-5cd058f0cc68/https://www.flying.co.il/assets/img/bg1-blue.png");
  }
}
@media ( max-width: 786px){
  .dVacation-pack .ticketLeft {
    position: relative;
    top: 0px;
  }
  .ticket-topPart-big, .ticket-bottomPart-big{
    padding-left: 0px;
    padding-right: 0px;
  }
  .dVacation-pack .ticket-topPart-big .box,
  .dVacation-pack .ticket-bottomPart-big .box {
    min-width: 62px;
    padding: 5px 10px !important;
  }

  .flightpagelist .box-middle img {
    display: block;
  }

  .dVacation-pack .ticketLeft {
    display: block;
  }

  div.card.card-body .plaindetails-box:nth-child(2){
    margin-top: 25px;
}

}
@media (max-width: 479px) {
  .dVacation-pack.filter-cont .filterItem:not(:first-child) {
    margin-top: 20px;
  }
  .dVacation-pack .flightpagelist .border-md-right [class^="ticket-topPart"] {
    margin-top: 3px;
  }
  .dVacation-pack .pricing .pricing_right .btn-group-sm > .btn,
  .dVacation-pack .pricing .pricing_right .btn-sm {
    margin: 1px;
  }
  .hot-offer-icon {
    width: 50px;
    height: 50px;
    top: -4px;
    left: -4px;
  }
  .pricing_left {
    padding : 3px 20px;
  }

  .flight-img{
    padding-right: 15px;
  }

  .dVacation-pack .flightpagelist .pricing span {
    display: block;
    font-size: 15px;
  }
  .dVacation-pack .flightpagelist .pricing .btn.btn-continue {
    /* float: right; */
    font-size : 15px;
  }
  .flight-select {
    position: absolute;
    top: 40%;
    right: -1%;
    padding: 0;
  }
  .flight-select {
    top: 43%;
  }
 #filter-content > .selected {
    box-shadow: 0px 0px 15px 3px #007bff !important;
  }

}
</style>
